import {
  BaseOrderPayload,
  IOrderFactory,
  Order,
  OrderType,
  RawOrder,
  SimpleFactory,
  SimpleLimitOrder,
  SimpleMarketOrder,
  SimpleStopOrder
} from "../../types/Trading.ts";
import {
  createLimitOrder,
  createMarketOrder,
  createOtocoOrder,
  createSlOtoOrder,
  createStopOrder,
  createTpOtoOrder
} from "./SpecificOrderFactories.ts";
import moment from "moment/moment";

export class OrderFactory implements IOrderFactory {
  constructor(
    private readonly type: OrderType,
    private readonly payload: BaseOrderPayload
  ) {}

  create() {
    const { side, symbol, quantity, price, stopLoss, takeProfit, timeInForce } = this.payload;

    if (stopLoss && takeProfit)
      return createOtocoOrder({
        type: this.type,
        symbol,
        side,
        quantity,
        price,
        stopLoss,
        takeProfit
      });
    if (takeProfit)
      return createTpOtoOrder({
        type: this.type,
        symbol,
        side,
        quantity,
        price,
        takeProfit
      });
    if (stopLoss)
      return createSlOtoOrder({
        type: this.type,
        symbol,
        side,
        quantity,
        price,
        stopLoss
      });

    const factory: Record<
      OrderType,
      SimpleFactory<SimpleMarketOrder | SimpleLimitOrder | SimpleStopOrder>
    > = {
      Market: createMarketOrder,
      Limit: createLimitOrder,
      Stop: createStopOrder
    };

    return factory[this.type](this.payload);
  }
}

const getLevelFromRawOrder = (raw: RawOrder): number => {
  if (raw.Type === "Stop" && raw.StopPrice) return raw.StopPrice;
  if (["Market", "Limit"].includes(raw.Type) && raw.Price) return raw.Price;

  return 0;
};
export const createOrder = (raw: RawOrder, isLeg = false): Order => {
  const activeStatuses = ["New", "Partially Filled", "Replaced", "Stopped", "Held", "Suspended"];
  const lowerCasedSide = raw.Side.toLowerCase();
  const quantity =
    lowerCasedSide.includes("buy") && raw.Quantity > 0 ? raw.Quantity : raw.Quantity * -1;
  const legs = Array.isArray(raw.Legs) ? raw.Legs.map((leg) => createOrder(leg, true)) : [];
  const level = getLevelFromRawOrder(raw);

  const order = {
    id: raw.Id,
    symbol: raw.Symbol,
    accountId: raw.AccountId,
    quantity,
    price: raw.Price || raw.StopPrice || 0,
    side: raw.Side,
    status: raw.Status,
    isActive: activeStatuses.includes(raw.Status),
    statusDescription: raw.Description || raw.Status,
    type: raw.Type,
    level: level,
    executionInstructions: raw.ExecutionInstructions,
    parentId: raw.ParentId,
    averagePrice: raw.AveragePrice,
    transactionDate: raw.TransactionDate === null ? null : moment(new Date(raw.TransactionDate)),
    timeInForce: raw.TimeInForce ?? "GoodTillCancel"
  };

  return !isLeg ? { ...order, legs } : order;
};
