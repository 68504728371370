import { OrderFactory } from "../../factories/Trading/OrderFactory.ts";
import { BaseOrderPayload, OrderType } from "../../types/Trading.ts";
import { isRealOrderPlacementAvailable, OrderService } from "../OrderService.ts";
import { useEtnaTrading } from "../../../stores/etna-trading.ts";
import { AxiosResponse } from "axios";

export class PlaceOrder {
  public exec = async (
    type: OrderType,
    payload: BaseOrderPayload
  ): Promise<AxiosResponse<any> | null> => {
    console.log("Payload: ", payload);
    const tradingStore = useEtnaTrading();
    const orderService = new OrderService(tradingStore.getAxios());
    const factory = new OrderFactory(type, payload);
    console.log("Factory: ", factory);
    const order = factory.create();
    console.log("Order aus Factory: ", order);

    try {
      if (isRealOrderPlacementAvailable())
        return await orderService.placeOrder(tradingStore.accountId as unknown as number, order);
      else alert(`OrderType: ${type}; \n\nPayload: ${JSON.stringify(order)}`);
      return null;
    } catch (e) {
      console.error(e, "while trying to execute placeOrder action");
      return null;
    }
  };
}
